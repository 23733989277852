.icons-container{
    height: 35vh;
    overflow: hidden;
    padding: 2vw;
    justify-content: space-between;
}

.icons-wrapper{
    display: flex;
    animation: scroll 30s linear infinite;
    white-space: nowrap;
}

@keyframes scroll{
    0% { transform: translateX(100vw);}
    25% {transform: translate(50vw);}
    50% { transform: translateX(0vw);}
    75% { transform: translateX(-50vw);}
    100% { transform: translateX(-100vw);}
}

.icons-wrapper:hover{
    animation-play-state: paused;
}

.icons-header{
    text-align: center;
    font-size: 2.5vw;
}

.icons-container img{
    height: 10vw;
    width: auto;
} 