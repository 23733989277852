@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}
.hobby-card-container{
    flex: 0.4;
    display: flex;
    background-color: #E4F3EF;
    border: 5px solid black;
    border-radius: 100px 25px 100px 25px;
    padding: 30px;
}

.unclicked-container h1 {
    font-family: StampborRounded;
    font-size: 45px;
}

.clicked-container p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 24px;
}


.card-grid{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin: 20px 20px 20px 45px;
    padding: 30px;
}
.card {
    display: flex;
    align-self: center;
    color: #E4F3EF;
    background-color: #13919E;
    border: 10px solid #095D67;
    border-radius: 1vw;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: 500ms;
    cursor: pointer;
    transform: perspective(500px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
    -moz-transform: perspective(500px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
    -webkit-transform: perspective(500px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
    height: 250px;
    width: 50%;
    margin: 10px;
}
.card.flip {
    --rotate-y: 180deg;
}
.card .front,
.card .back {
    position: absolute;
    padding: 1rem;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-origin: center center; /* Ensure correct transform origin */
    -moz-transform-origin: center center;
    -webkit-transform-origin: center center;
}
.card .back {
    transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}
.card .front {
    margin: 50px;
    width: 80%;
    height: fit-content;
}
.card .front h1{
    text-align: center;
    word-wrap: break-word;
    font-size: 1.3vw;
    margin: 20px;
}
.card .back p {
    color:#E4F3EF;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1vw;
}
.card.flip .front {
    visibility: hidden;
}

@media (max-width: 1000px) {
    .card {
        width: 90%;
        padding: 3vw;
    }
    .card .front h1 {
        font-size: 3vw;
    }
    .card .back p {
        font-size: 2.5vw;
    }
}