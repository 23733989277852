.social-media-links {
    display: flex;
    flex-direction: row;
    align-items:center;
    margin: 1.25vw;
}
.link {
    margin-inline: 40px; /* Adjust spacing between social media icons */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25vw;
}

.link img {
    border-radius: 0;
    width: 65%; /* Adjust the width of the social media icons */
    height: 65%; /* Adjust the height of the social media icons */
    background-color: #DE9050;
    padding: 5px;
    border: 3px solid #151515;
    border-radius: 25%;
}

.link img:hover{
    width: 85%;
    height: 85%;
    background-color: #C36211;
    transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 250ms;
}

.link span {
    align-self: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    margin-left: 0; /* Adjust spacing between the icon and the name */
    font-size: 16px; /* Adjust the font size of the social media names */
    color: #333; /* Adjust the color of the social media names */
}