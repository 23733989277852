@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}

.project-card-container {
    width: inherit;
    height: 100%;
    margin: 1vw;
    padding: 1vw;
    align-self: center;
    display: flex;
    flex-direction: row;
    background-color: #E4F3EF;
    color: #0D1B2A;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: inherit;
}

.project-card-header {
    flex: 0.4;
    padding-inline: 10px;
    align-self: center;
    text-align: center;
}
.project-card-header h1 {
    font-size: 25px;
    font-family: StampborRounded;
}
.project-card-header h3 {
    font-size: 15px;
}
.project-card-header h4 {
    font-size: 12px;
}

.project-card-body {
    flex: 0.8;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    border-radius: inherit;
}
.project-card-body p {
    padding: 15px;
    align-self: center;
    text-align: left;
    font-size: 18px;
    font-weight: normal;
}
.project-card-body h4 {
    flex: 0.2;
    text-align: center;
    font-size: 18px;
}

.github-button {
    width: 60%;
    padding: 10px;
    margin: 10px;
    align-self: center;
    background-color: #13919E;
    color:#151515;
    border-radius: 0.5vw;
    border-color: #095D67;
}
.github-button:hover{
    background-color: #DE9050;
    border-color: #13919E;
    cursor: pointer;
}
.github-button h5 {
    height: fit-content;
    margin: 10px;
    text-wrap: wrap;
    word-wrap: break-word;
    font-size: 15px;
    color:#0D1B2A;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media (max-width: 650px) {
    .project-card-container{
        width: 75%;
        flex-direction: column;
    }
    .project-card-body p {
        text-align: center;
    }
}