@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}

.list-item-container {
    border: 10px solid #C36211;
    border-radius: 8px;
    box-shadow: 0 2px 4px #0D1B2A;
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: 5vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.list-item-header {
    background-color: #DE9050;
    color:#151515;
    padding: 10px 20px;
    border-bottom: 5px solid #0D1B2A;
}
.list-item-header:hover{
    background-color: #C36211;
    color:#151515;
}

.list-item-header h4 {
    font-family: StampborRounded;
    text-align: center;
    color: inherit;
    font-size: 2vw;
}
.list-item-body {
    padding: 2vh;
}
.list-item-body p {
    margin: 0;
    text-align: center;
    font-size: 1.5vw;
}

@media (max-width: 750px){
    .list-item-container{
        width: 75%;
        align-self: center;
    }
    .list-item-header h4 {
        font-size: 4vw;
    }
    .list-item-body p {
        font-size: 3vw;
    }
}