@font-face {
    font-family: DoctorGlitch;
    src: url('../resources/fonts/DoctorGlitch.otf') format("opentype");
}
@font-face {
    font-family: CrackedCode;
    src: url('../resources/fonts/CrackedCode.ttf') format("truetype");
}
@font-face {
    font-family: Prisma;
    src: url('../resources/fonts/Prisma.otf') format("opentype");
}
@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}

.home-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #13919E;
}

.intro-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 5vw;
    padding-bottom: 5vw;
}

.left-side {
    height: 100%;
    flex: 0.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: #DE9050;
    border: 10px solid #095D67;
    border-radius: 5px;
    color: #151515;
}
.btn {
    background-color: transparent;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
  }
/* 
========================
      BUTTON ONE
========================
*/
.btn-one {
    border-color: green;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    font-family: Helvetica;
    color: #151515;
    transition: all 0.3s;
    position: relative;
    justify-content: space-between;
    border-radius: 25px;
  }
  .btn-one span{
    text-decoration: none;
  }
  .btn-one img {
    flex: 0.3;
    height: 100%;
  }
  .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #095D67;
    border-bottom-color: #095D67;
    transform: scale(0.1, 1);
  }
  .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: #C3621144;
    border-radius: 5px;
  }
  .btn-one:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }

.left-side h1{
    font-family: StampborRounded;
    text-transform: uppercase;
    font-size: 4vw;
}
.left-side h2{
    font-size: 2vw;
    font-family: StampborRounded;
}
.left-side h3{
    font-size: 1.5vw;
    font-family: StampborRounded;

}
.left-side h1, h2, h3{
    text-align: center;
}
.left-side h1, h2, h3:hover{
    text-align: center;
}
.right-side {
    flex:0.5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #13919E;
    border-radius: 5px;
    border: 10px solid #DE9050;
}

.right-side img:hover{
    width: 80%;
    transition: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 250ms;
}

.right-side img {
    width: 65%;
    border-radius: 75px;
    padding: 20px;
    max-height: auto;
}

.right-side img::after{
    transition: 250ms ease 250ms;
}

/* UPDATE CONTAINER STYLES*/

.update-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #E4F3EF;
    border: 10px solid #095D67;
    margin-top: 20px;
}
.update-header{
    text-align: center;
    font-family: CrackedCode;
    text-transform: uppercase;
    padding-top: 2vw;
}
.update-header h1 {
    font-size: 4vw;
    color: #C36211;
    margin: 1vh;
    padding-block: 2vh;
}

.updates{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1vh;
    padding: 1vh;
}
@media (max-width: 750px){
    .intro-container{
        flex-direction: column;
    }
    .left-side {
        margin: 2vw;
        justify-content: center;
    }
    .left-side h1 {
        font-size: 10vw;
    }
    .left-side h2 {
        font-size: 5vw;
    }
    .left-side h3 {
        font-size: 4vw;
    }
    .right-side{
        margin: 2vw;
    }
    .update-container {
        justify-content: center;
        align-items: center;   
    }
    .updates {
        flex-direction: column;
        width: 100%;
    }
    .update-header h1 {
        font-size: 5vw;
        padding: 5vw;
    }
    
}