@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}

.contact-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
    background-color: #13919E;
    font-family: StampborRounded;
}

.contact-header {
    display: flex;
    flex-direction: column;
    padding: 3vw;
    margin: 3vw;
    align-self: center;
    align-content: center;
    background-color: #DE9050;
    border-radius: 0.5vw;
    border: 10px solid #095D67;
}

.contact-header h1 {
    text-align: center;
    font-size: 3vw;
}
.contact-header h3 {
    width: 70%;
    align-self: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    word-wrap: normal;
    font-size: 1vw;
    font-weight: lighter;
}

.contact-form-body{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 70%;
}

.contact-form-body form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contact-form-name-email{
    padding: 2vw;
    display: flex;
    flex-direction: column;
    flex:0.4;
}
.contact-form-name-email label {
    color: #151515;
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}
.contact-form-name-email input {
    padding: 10px;
    border-radius: 0.5vw;
    margin-top: 10px;
    margin-bottom: 10px;
}
.contact-form-name-email input:focus{
    border-color: #DE9050;
    border-width: 5px;
}

.contact-form-message {
    padding: 2vw;
    flex:0.4;
    justify-self: center;
}
.contact-form-message label {
    color: #151515;
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}
.contact-form-message textarea {
    height: 100%;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    padding: 10px;
    margin-top: 20px;
    border-radius: 0.5vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contact-form-message textarea:focus {
    border-color: #DE9050;
    border-width: 5px;
}

.submit {
    align-self: center;
    width: 120px;
    height: fit-content;
    background-color: #DE9050;
    color:#151515;
    border: 5px solid #095D67;
    border-radius: 0.5vw;
    margin-bottom: 50px;
}
.submit:hover{
    background-color: #C36211;
    border: 5px solid #095D67;
}
.submit h3 {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

@media (max-width: 1000px) {
    .contact-header h1 {
        font-size: 6vw;
    }
    .contact-header h3 {
        font-size: 4vw;
    }
}