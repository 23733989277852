@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}

.project-main-container {
    display: flex;
    width: 100vw;
    flex-direction: column;
    background-color: #13919E;
}

.project-main-header {
    width: 30%;
    margin: 3vw;
    padding: 3vw;
    align-self: center;
    align-content: center;
    background-color: #DE9050;
    border-radius: 0.5vw;
    border: 10px solid #095D67;
}
.project-main-header h1{
    font-family: StampborRounded;
    text-align: center;
    font-size: 3vw;
    color: #151515;
}

.projects-container {
    width: 99%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.projects-container h2 {
    margin: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 40px;
    font-family: StampborRounded;
}

.in-progress-projs,
.completed-projs {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-content: center;
    background-color: #DE9050;
    color: #151515;
    border-radius: 0.5vw;
    border: 10px solid #095D67;
    padding: 3vw;
    margin: 3vw;
}

@media (max-width: 650px) {
    .project-main-header {
        width: 60%;
    }
    .project-main-header h1 {
        font-size: 5vw;
    }
    .projects-container {
        flex-direction: column;
        justify-content: space-evenly;
        width: 75%;
    }
    .projects-container h2 {
        font-size: 6vw;
    }
    .in-progress-projs,
    .completed-projs {
        width: 85%;
        margin-block: 20px;
        border-radius: 75px;
    }
}