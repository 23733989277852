@font-face {
    font-family: StampborRounded;
    src: url('../resources/fonts/Stampbor-Rounded.otf') format("opentype");
}
.about-container{
    width: 100%;
    display:flex;
    flex-direction: column;
    background-color: #13919E;
    font-family: StampborRounded;
}
.about-bio{
    width: 60%;
    display: flex;
    margin: 3vw;
    padding: 3vw;
    flex-direction: column;
    align-self: center;
    background-color: #DE9050;
    border-radius: 0.5vw;
    border: 10px solid #095D67;
    text-align: center;
    color: #151515;
}
.about-bio-resume-download {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-self: center;
    cursor: pointer;
}
.resume-icon {
    height: 50px;
    width: auto;
    padding: 2vw;
    margin-top: 15px;
    align-self: center;
    background-color: #DE9050;
}
.about-bio h3 {
    font-weight: 100;
    font-size: 2vw;
}
.about-bio h4 {
    font-weight: 100;
    font-size: 1.5vw;
    margin:0;
}
.about-bio p {
    font-size: 1.5vw;
    font-weight: 100;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-inline: 50px;
    margin: 0;
    padding-inline: 50px;
    padding-bottom: 0;
    padding-top: 20px;
}

.about-skills {
    display: flex;
    width: 80%;
    align-self: center;
    flex-direction: column;
    background-color: #E4F3EF;
    border: 10px solid #095D67;
    border-radius: 1vw;
    /*background-image:repeating-radial-gradient(circle at left, #5BDAAD, #0D1B2A, #5BDAAD);*/
    justify-content: space-evenly;
}

.hobby-box {
    margin-block: 50px;
    width: 75%;
    align-self: center;
    justify-content: space-evenly;
    display: flex;
    background-color: #DE9050;
    border: 10px solid #C36211;
    border-radius: 1vw;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
    font-family: StampborRounded;
}
.hobby-box-left {
    flex: 0.25;
    background-color: #E4F3EF;
    border: 10px solid #C36211;
    border-radius: 1vw;
    padding: 2vw;
    margin: 3vw;
    align-self: center;
}
.hobby-list-header {
    font-size: 1.5vw;
    align-items: center;
}

.hobby-list-header h2 {
    font-size: 40px;
    color: #151515;
}

.hobby-list-list {
    justify-content: center;
}

.hobby-list-list ul {
    list-style-type: none;
    text-align: center;
    padding-right: 40px;
    color: #151515;
}

.hobby-list-list h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 25px;
    font-weight: lighter;
}

.hobby-box-right {
    flex: 0.75;
    background-color: #C36211;
    border-radius: 1vw;
    padding: 2vw;
    margin: 3vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.hobby-card-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-evenly;
}

h2{
    font-size: 3vw;
    text-align: center;
}
p{
    text-align: center;
}
.note {
    background-color: #13919E;
    border: 0.25vw solid #095D67;
    border-radius: 0.5vw;
}

@media (max-width: 750px) {
    .about-bio h2{
        font-size: 7vw;
    }
    .about-bio h3{
        font-size: 3.5vw;
    }
    .about-bio h4{
        font-size: 3.5vw;
    }
    .about-bio p{
        font-size: 3vw;
        padding-inline: 0;
    }
    .about-bio-resume-download h4 {
        font-size: 2vw;
    }
    .about-bio-resume-download img {
        height: 5vw;
        width: 5vw;
    }
    .about-skills{
        width: 80%;
        height: 30vh;
    }
    .about-skills h2 {
        font-size: 5vw;
    }
    .hobby-box {
        flex-direction: column;
        width: 80%;
        background-color: #DE9050;
    }
}
@media (min-width: 1001px){
    .about-bio h2 {
        font-size: 3vw;
    }
    .about-bio h3 {
        font-size: 1.5vw;
    }
    .about-bio h4 {
        font-size: 1vw;
    }
    .about-bio p {
        font-size: 1.5vw;
    }
}